$(document).ready(function() {

	function OpenWindow(targetWindow) {
		$(targetWindow).arcticmodal({
			closeOnEsc: false,
			closeOnOverlayClick: false,
			openEffect: {
				type: "fade",
				speed: 300
			},
			overlay: {
				css: {
					backgroundColor: "#0c1113",
					opacity: .75
				}
			}
		});
	}

	$(".js-order_button").click(function() {
		OpenWindow(".js-order_window");
	});

});

function showHidePassword(target) {
	let input = document.getElementById('password-input');
	if (input.getAttribute('type') == 'password') {
		target.classList.add('view');
		input.setAttribute('type', 'text');
	} else {
		target.classList.remove('view');
		input.setAttribute('type', 'password');
	}
	return false;
}

window.showHidePassword = showHidePassword;